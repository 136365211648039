<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Overzicht
                </h2>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
                <!-- Table Inkomsten -->
                <div class="flex flex-col">
                    <div class="overflow-x-auto">
                        <div class="py-6 align-middle inline-block min-w-full mx-4 md:mx-0 sm:px-6 lg:px-8">
                            <h3 class="text-white mb-4 font-bold uppercase">Inkomsten</h3>
                            <div class="shadow overflow-hidden">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th v-for="row in table1rows" :key="row.key" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                <div class="flex items-center">
                                                    <div>{{ row.text }}</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-500">
                                        <template v-if="incomes.length">
                                            <tr v-for="income in incomes" :key="income.month">
                                                <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
                                                    {{ income.month }}              
                                                </td>
                                                <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
                                                    {{ income.analysis }}              
                                                </td>
                                                <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
                                                    {{ income.income }}
                                                </td>
                                            </tr>
                                        </template>
                                        <tr v-else>
                                            <td :colspan="rows.length" class="px-4 py-5 whitespace-nowrap text-sm font-medium text-center text-white">
                                                Er is momenteel geen data beschikbaar
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Table Uitbetalingen -->
                <div class="flex flex-col">
                    <div class="overflow-x-auto">
                        <div class="py-6 align-middle inline-block min-w-full mx-4 md:mx-0 sm:px-6 lg:px-8">
                            <h3 class="text-white mb-4 font-bold uppercase">Uitbetalingen</h3>
                            <div class="shadow overflow-hidden">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th v-for="row in table2rows" :key="row.key" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                <div class="flex items-center">
                                                    <div>{{ row.text }}</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-500">
                                        <template v-if="incomes.length">
                                            <tr v-for="income in incomes" :key="income.month">
                                                <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
                                                    {{ income.month }}              
                                                </td>
                                                <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
                                                    {{ income.payed }}              
                                                </td>
                                            </tr>
                                        </template>
                                        <tr v-else>
                                            <td :colspan="rows.length" class="px-4 py-5 whitespace-nowrap text-sm font-medium text-center text-white">
                                                Er is momenteel geen data beschikbaar
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";

export default {
    name: "FinancialOverview",
    components: {
        DashboardWrapper,
    },

    data() {
        return {
            table1rows: [
                { key:'month', text:'Maand' },
                { key:'analysis', text:'Analyses' },
                { key:'income', text:'Inkomsten' },
            ],

            table2rows: [
                { key:'month', text:'Maand' },
                { key:'payed', text:'Uitbetaald' },
            ],

            // Testdata
            incomes: [
                { month: 'Januari',         analysis: 3,         income: 24.95,      payed: 12.20 },
                { month: 'Februari',        analysis: 3,         income: 24.95,      payed: 37.15 },
                { month: 'Maart',           analysis: 3,         income: 24.95,      payed: 0.00  },
                { month: 'April',           analysis: 3,         income: 24.95,      payed: 49.90 },
                { month: 'Mei',             analysis: 3,         income: 24.95,      payed: 12.20 },
                { month: 'Juni',            analysis: 3,         income: 24.95,      payed: 37.15 },
                { month: 'Juli',            analysis: 3,         income: 24.95,      payed: 37.15 },
                { month: 'Augustus',        analysis: 3,         income: 24.95,      payed: 12.20 },
                { month: 'September',       analysis: 3,         income: 24.95,      payed: 24.95 },
                { month: 'Oktober',         analysis: 3,         income: 24.95,      payed: 24.95 },
                { month: 'November',        analysis: 3,         income: 24.95,      payed: 0.00  },
                { month: 'December',        analysis: 3,         income: 24.95,      payed: 49.90 },
            ],
        }
    },  
}
</script>