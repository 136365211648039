<template>
    <!-- Desktop --> 
    <div @click="open = !open" class="hidden md:inline-flex items-center" :class="{'text-orange': subIsActive('/' + item.routetext.toLowerCase())}">
        <div>{{ item.text }}</div>
        <svg v-if="item.subnav" class="ml-1 w-4 h-4 transition-transform duration-100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
    </div>
    <transition name="fade">
        <div v-if="open && item.subnav" class="hidden md:block absolute mt-4 mr-2 w-56 rounded-md shadow-lg">
            <div class="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                <router-link 
                v-for="(item, index) in item.subnav" 
                :to="item.to" :key="index" 
                active-class="" 
                exact-active-class="" 
                class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">
                    {{ item.text }}
                </router-link>
            </div>
        </div>
    </transition>

    <!-- Mobile -->
    <div @click="open = !open" class="md:hidden flex justify-between mt-1 pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-300 hover:bg-gray-900" :class="{'router-link-active-mobile': subIsActive('/' + item.routetext.toLowerCase())}">
            <div> 
                {{ item.text }} 
            </div>
        <svg v-if="item.subnav" class="mt-1 w-4 h-4 transform transition-transform duration-100" :class="{'rotate-0':open, '-rotate-90':!open}" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
    </div> 
    <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave" class="md:hidden">
        <div v-if="open">
            <router-link v-for="(item, index) in item.subnav" :to="item.to" :key="index" active-class="router-link-active-mobile" exact-active-class="" class="flex justify-between mt-1 pl-8 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-300 hover:bg-gray-900">
                {{ item.text }}
            </router-link>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Subnav',    
    props: ['item'],

    data() {
        return {
            open: false,
        }
    },

    methods: {
        enter(el) {
            el.style.height = 'auto';
            const height = getComputedStyle(el).height;
            el.style.height = 0;
            getComputedStyle(el);

            setTimeout(() => {
                el.style.height = height;
            });        
        },
      
        afterEnter(el) {
            el.style.height = 'auto';
        },
        
        leave(el) {
            el.style.height = getComputedStyle(el).height;
            getComputedStyle(el);

            setTimeout(() => {
                el.style.height = 0;
            });
        },

        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0;
            });
        },
    }
}
</script>

<style>
    .router-link-active-mobile {
        border-left: 4px solid #F7941D;
    }

   .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }

    .expand-enter-active, .expand-leave-active {
        transition: height .2s ease-in-out;
        overflow: hidden;
    }
</style>