<template>
    <div class="bg-scorelitgray z-20">
        <div class="py-6 md:py-3 relative z-10 shadow flex flex-col md:flex-row justify-around h-full px-4 sm:px-8">
            <div class="text-white text-center md:text-left text-xs font-medium my-auto md:w-1/3 order-5 md:order-none">
                &copy; {{ currentYear }} | Made in the Netherlands
            </div>

            <div class="flex-initial flex md:justify-center my-auto md:w-1/3 order-4 md:order-none pb-1 md:pb-0">
                <img class="h-5 mx-auto" src="@/assets/images/logo/emblem.svg" alt="Scorelit logo">
            </div>

            <div class="text-white text-center md:text-left text-xs my-auto md:flex justify-end md:w-1/3 pb-5 md:pb-0 space-y-2 md:space-y-0">
                <a href="/help" class="block h-full my-auto md:pr-8 font-medium transition duration-300 hover:text-gray-400">
                    Help
                </a>
                <a href="https://www.scorelit.com/voorwaarden/" target="_blank" class="block h-full my-auto font-medium transition duration-300 hover:text-gray-400">
                    Algemene voorwaaden
                </a>
                <a href="https://www.scorelit.com/privacy-statement/" target="_blank" class="block h-full my-auto md:pl-8 font-medium transition duration-300 hover:text-gray-400 ">
                    Privacy Statement
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',

    data() {
        return {
            currentYear: new Date().getFullYear(),
        }
    },
}
</script>