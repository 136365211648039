<template>
    <div class="z-0 relative bg-scorelitgray">
        <div class="relative z-10 shadow">
            <div class="flex">
                <!-- Logo -->
                <div class="flex-initial pl-4 sm:pl-8 my-auto">
                    <router-link :to="{name: 'dashboard'}">
                        <img class="h-6 md:h-8" src="@/assets/images/logo/logo-white.svg" alt="Scorelit logo">
                    </router-link>
                </div>
                
                <!-- Desktop: Main menu -->
                <nav class="flex-auto flex items-center justify-end md:px-6">
                    <div v-for="(item, index) in menu_items" :key="index" class="text-white font-normal my-auto px-5 hidden md:block transition cursor-pointer duration-200 hover:text-scorelitred">
                        <SubNav v-if="item.subnav" :item="item"/>
                        <router-link v-else 
                        :to="item.to" 
                        active-class="text-orange" 
                        exact-active-class="text-orange" 
                        class="inline-flex items-center">
                            {{ item.text }}
                        </router-link>
                    </div>
                </nav>

                <!-- Desktop: User badge -->
                <div class="hidden md:block">
                    <div class="flex-initial shape py-4 bg-gradient-to-r from-gradient-orange to-gradient-red flex content-center cursor-pointer" @click="show_sub_menu = !show_sub_menu">
                        <div class="pl-16 my-auto text-white pt-2 pb-1">
                            <svg viewBox="162 25 190 248" class="h-12" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com"  stroke="currentColor">
                                <defs>
                                    <pattern id="img1" patternUnits="userSpaceOnUse" width="400" height="300">
                                    
                                    </pattern>
                                </defs>
                                <path fill="url(#img1)" stroke-width="8" d="M 164.794 46.508 L 164.794 222.047 C 164.954 229.85 166.872 233.817 173.423 237.784 C 194.512 250.555 222.796 259.185 245.811 268.466 C 252.63 271.216 261.418 271.056 269.301 267.987 C 295.503 257.784 315.477 248.835 340.73 236.346 C 345.983 233.748 348.061 230.393 347.922 222.444 L 347.922 38.777 C 347.602 32.885 344.406 29.55 338.334 28.771 L 177.217 28.771 C 169.241 29.41 165.1 35.323 164.794 46.508 Z"/>
                            </svg>
                        </div>
                        <div class="pl-8 pr-4 md:pl-4 md:pr-8 my-auto">
                            <span>
                                <span class="align-middle inline-block">
                                    <p class="text-white font-medium text-sm unselectable" unselectable="on">Vnaam</p>
                                </span>
                                <span class="hidden align-middle md:inline-block text-white pl-2">
                                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Mobile: User badge -->
                <div class="block md:hidden">
                    <div class="flex-initial shape py-3 bg-gradient-to-r from-gradient-orange to-gradient-red flex content-center cursor-pointer" @click="show_mobile_menu = !show_mobile_menu">
                        <div class="pl-8 pr-4 md:px-8 my-auto">
                            <span class="align-middle inline-block">
                                <p class="text-white font-semibold text-sm unselectable" unselectable="on">Vnaam</p>
                            </span>
                            <span class="inline-block align-middle md:hidden text-white pl-2">
                                <svg v-if="show_mobile_menu" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
                                <svg v-else class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Desktop: Sub menu dropdown -->
                <transition name="fade">
                    <div v-if="show_sub_menu" class="hidden md:block origin-top-right absolute right-0 mt-20 mr-2 w-56 rounded-md shadow-lg">
                        <div class="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                            <router-link 
                                v-for="(item, index) in sub_menu_items" 
                                :to="item.to" 
                                :key="index" 
                                active-class="" 
                                exact-active-class="" 
                                class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">
                                    {{ item.text }}
                            </router-link>
                            <a @click.prevent="logout" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer unselectable" role="menuitem">
                                Uitloggen
                            </a>
                        </div>
                    </div>
                </transition>
            </div>   

            <!-- Mobile: menu -->
            <div v-if="show_mobile_menu" class="md:hidden">
                <div class="pt-2 pb-3">
                    <div v-for="(item, index) in menu_items" :key="index" class="cursor-pointer">
                        <SubNav v-if="item.subnav" :item="item" />
                        <router-link v-else 
                        :to="item.to" 
                        active-class="router-link-active-mobile" 
                        exact-active-class="" 
                        class="flex justify-between mt-1 pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-300 hover:bg-gray-900">
                            {{ item.text }}       
                        </router-link>
                    </div>
                    <div v-for="(item, index) in sub_menu_items" :key="index">
                        <router-link
                        :to="item.to" 
                        active-class="router-link-active-mobile" 
                        exact-active-class="" 
                        class="flex justify-between mt-1 pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-300 hover:bg-gray-900">
                            {{ item.text }}       
                        </router-link>    
                    </div>
                    <a @click.prevent="logout" class="flex justify-between mt-1 pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-300 hover:bg-gray-900 cursor-pointer">
                        Uitloggen
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubNav from "@/components/dashboard/SubNav";

export default {
    name: 'Header',
    components: {
        SubNav,
    },

    data() {
        return {
            menu_items: [
                {
                    text: 'Dashboard',
                    routetext: 'Dashboard',
                    to: { name: 'dashboard' },
                },
                {
                    text: 'Wedstrijden',
                    routetext: 'Matches',
                    to: { name: 'matches.index' },
                },
                {
                    text: 'Club',
                    routetext: 'Club',
                    subnav: [
                        { 
                        text: 'Leden', 
                        routetext: 'Members',
                        to: { name: 'members' },
                        }, 
                        { 
                        text: 'Coaches', 
                        routetext: 'Coaches',
                        to: { name: 'coaches' },
                        }, 
                        { 
                        text: 'Teams',
                        routetext: 'Teams', 
                        to: { name: 'teams' },
                        }, 
                    ]
                },
                {
                    text: 'Financieel',
                    routetext: 'Financial',
                    subnav: [
                        { 
                        text: 'Overzicht', 
                        routetext: 'Overview', 
                        to: { name: 'overview' },
                        }, 
                        { 
                        text: 'Transacties', 
                        routetext: 'Transactions',
                        to: { name: 'transactions' },
                        },  
                    ]
                },
                {
                    text: 'Instellingen',
                    routetext: 'Settings',
                    to: { name: 'club.settings' },
                }
            ],
            
            sub_menu_items: [
            {
                    text: 'Mijn account',
                    routetext: 'Account',
                    to: { name: 'account.settings' },
                }
            ],

            show_sub_menu: false,
            show_mobile_menu: false,
        }
    },
}
</script>

<style scoped>
    .shape {
        clip-path: polygon(18% 0%, 100% 0%, 100% 100%, 0% 100%);
    }    

    .router-link-active-mobile {
        border-left: 4px solid #F7941D;
    }

   .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>